//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Slider du haut de l'accueil -----------------------------------------------------------------------
export function slickHome() {

  $('#slickSlides').slick({
    arrows: true,
    prevArrow: $('#topSlider .prev'),
    nextArrow: $('#topSlider .next'),
    dots: false,
    infinite: true,
    draggable: true,
    easing: 'easeInOutExpo',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,		
    speed: 1000,
    rows: 0,
    pauseOnHover: false,
    fade: true,
  })

  $('#slickSlides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('#topSliderPager .currentSlide').html(nextSlide + 1)

    var followingSlide = nextSlide + 2

    if (followingSlide > slick.slideCount) {
      followingSlide = 1
    }

    $('#topSliderPager .spacer').toggleClass('reset');
    $("#topSliderPager .totalSlides").html(slick.slideCount);
      
	});
}

// Slider des événements -----------------------------------------------------------------------------
export function slickEvents() {
  $('#slickEventsOverSlider').slick({
    asNavFor: '#slickEvents',
    arrows: true,
    prevArrow: $('.eventsSlider .prev'),
    nextArrow: $('.eventsSlider .next'),
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
  })

  $('#slickEvents').slick({
    asNavFor: '#slickEventsOverSlider',
    arrows: true,
    prevArrow: $('.eventsSlider .prev'),
    nextArrow: $('.eventsSlider .next'),
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
  })
}


// Slider des témoignages ----------------------------------------------------------------------------
export function slickTestimonials() {
  $('.testimonialsSlides').slick({
    arrows: true,
    prevArrow: $('.sectionTestimonials .prev'),
    nextArrow: $('.sectionTestimonials .next'),
    dots: false,
    infinite: true,
    draggable: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,		
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    fade: true,
  })

  $('.testimonialsSlides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('#testimonialsSliderPager .currentSlide').html(nextSlide + 1)

    var followingSlide = nextSlide + 2

    if (followingSlide > slick.slideCount) {
      followingSlide = 1
    }

    $('#testimonialsSliderPager .spacer').toggleClass('reset');
    $("#testimonialsSliderPager .totalSlides").html(slick.slideCount);
      
	});
}


// Slider des publicités -----------------------------------------------------------------------------
export function slickPublicites() {
  $('.bigBoxPublicites').slick({
    arrows: false,
    dots: false,
    infinite: true,
    draggable: false,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    fade: true
  })
  $('.smallBoxPublicites').slick({
    arrows: false,
    dots: false,
    infinite: true,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    speed: 500,
    rows: 0,
    pauseOnHover: false,
    fade: true
  })
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 100 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
