//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Si la taille de la fenêtre est modifié, fermeture du sidepanel s'il est ouvert
export function closeAllOnResize () {
	var width = $(window).width();

	$(window).on( 'resize', function() {
		var newWidth = $(window).width()
		if ( newWidth == width ) {
			return
		} else {
			width = $(window).width()
			$('html').removeClass('showOverlayMobile')
      $('.subLvl1').removeClass('open')
      $('.subLvl1').animate({height: 'hide'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
      $('.btnSubLvl1').removeClass('active')
			$('html').removeClass('showOverlayDropDown').removeClass('showPartenaire').removeClass('showTarif').removeClass('showLocation')
      $('.lienDropDown').removeClass('active')
			if($('html').hasClass('noscroll')) {
				var scrollPosition = parseInt($('html').css('top'))
				$('html').removeClass('noscroll')
				$('html,body').scrollTop(-scrollPosition)
			}
		}
	})
}

// Fonction gérant le menu mobile
export function overlayMenuMobile() {
	//$('.hamburger').BLK_toggleHtmlClass({ htmlClass: 'showOverlayMobile' })
	$('.hamburger, #overlay').on('click', function() {
    $('.subLvl1').removeClass('open')
    $('.subLvl1').animate({height: 'hide'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
    $('.btnSubLvl1').removeClass('active')
  })
	$('.btnSubLvl1 > a').on('click', function() {
		// Fermeture de tout avant d'en ouvrir un autre
		if(!$(this).parent().hasClass('active')) {
			$('.btnSubLvl1').removeClass('active')
			$('.subLvl1').animate({height: 'hide'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
			$('.subLvl1').removeClass('open')
		}
		// Gestion ouverture et fermeture
		if(!$(this).parent().find('.subLvl1').hasClass('open')) {
			$(this).parent().find('.subLvl1').addClass('open')
			$(this).parent().find('.subLvl1').animate({height: 'show'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
			$(this).parent().addClass('active')
		} else {
			$(this).parent().find('.subLvl1').removeClass('open')
			$(this).parent().find('.subLvl1').animate({height: 'hide'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
			$(this).parent().removeClass('active')
		}
	})
}

// Fonction gérant le menu drop down
export function overlayDropDown() {
	var activeDropDown = ''
  var scrollTop

  var addDropDown = function(self) {
    if ($(document).height() > $(window).height() && !$('html').hasClass('showOverlayDropDown')) {
      var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
      $('html').addClass('noscroll').css('top',-scrollTop)
    }
    $('.lienDropDown').removeClass('active')
    $('html').addClass('showOverlayDropDown')
    $('html').removeClass(activeDropDown)
    activeDropDown = self.data('section')
    $('html').addClass(activeDropDown)
    self.addClass('active')
  }

  var removeDropDown = function(self) {
    if($('html').hasClass('showOverlayDropDown')) {
      $('html').removeClass( activeDropDown )
      $('html').removeClass('showOverlayDropDown')
      $('.lienDropDown').removeClass('active')
      var scrollPosition = parseInt($('html').css('top'))
      $('html').removeClass('noscroll')
      $('html,body').scrollTop(-scrollPosition)
    }
  }

	$('.lienDropDown').on('click', function() {
    if(!$(this).hasClass('active')) {
      addDropDown($(this))
    } else {
      removeDropDown($(this))
    }
	})

	$('#overlayDropDown .btn, .closeOverlay, .secondaryMenu a, .btnClose').on('click', function() {
		removeDropDown($(this))
	})
}

// Fonction gérant la recherche drop down
export function overlayDropDownSearch() {
	var activeDropDown = ''
  var scrollTop

  var addDropDown = function(self) {
    if ($(document).height() > $(window).height() && !$('html').hasClass('showOverlayDropDownSearch')) {
      var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
      $('html').addClass('noscroll').css('top',-scrollTop)
    }
    $('.lienDropDownSearch').removeClass('active')
    $('html').addClass('showOverlayDropDownSearch')
    $('html').removeClass(activeDropDown)
    activeDropDown = self.data('section')
    $('html').addClass(activeDropDown)
    self.addClass('active')
  }

  var removeDropDown = function(self) {
    if($('html').hasClass('showOverlayDropDownSearch')) {
      $('html').removeClass( activeDropDown )
      $('html').removeClass('showOverlayDropDownSearch')
      $('.lienDropDownSearch').removeClass('active')
      var scrollPosition = parseInt($('html').css('top'))
      $('html').removeClass('noscroll')
      $('html,body').scrollTop(-scrollPosition)
    }
  }

	$('.lienDropDownSearch').on('click', function() {
    if(!$(this).hasClass('active')) {
      addDropDown($(this))
      $('#searchInput').focus();
    } else {
      removeDropDown($(this))
    }
	})

	$('#overlayDropDown .btn, .closeOverlay, .secondaryNav a, .btnClose').on('click', function() {
		removeDropDown($(this))
	})
}