//--------------------------------------------\\
//---- Sticky des événements de l'accueil ----\\
//--------------------------------------------\\

export function showSticky(delay) {
    var sticky = $('.nextEventFixed');
    setTimeout(function() {
        sticky.addClass('active');
    }, delay);
}

export function hideSticky() {
    var sticky = $('.nextEventFixed');
    sticky.find('.close').click(function() {
        sticky.removeClass('active');
    });
}