//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Generic, Events, Publications, Jobs, JobDetails, BecomeMember ,Repertoire, Recherche, Contact
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standard _______________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil _______________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()

  // Pages génériques __________
  Barba.BaseView.extend({
    namespace: 'genericView',
    onEnter:          function() {},
    onEnterCompleted: function() { Generic.initGeneric()  },
    onLeave:          function() { Generic.leaveGeneric() },
    onLeaveCompleted: function() {}
  }).init()

  // Pages des événements ______
  Barba.BaseView.extend({
    namespace: 'eventsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Events.initEvents()  },
    onLeave:          function() { Events.leaveEvents() },
    onLeaveCompleted: function() {}
  }).init()

  // Pages des événements ______
  Barba.BaseView.extend({
    namespace: 'publicationsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Publications.initPublications()  },
    onLeave:          function() { Publications.leavePublications() },
    onLeaveCompleted: function() {}
  }).init()

  // Pages des emplois _________
  Barba.BaseView.extend({
    namespace: 'jobsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Jobs.initJobs()  },
    onLeave:          function() { Jobs.leaveJobs() },
    onLeaveCompleted: function() {}
  }).init()

  // Page de detail de poste ___
  Barba.BaseView.extend({
    namespace: 'jobDetailsView',
    onEnter:          function() {},
    onEnterCompleted: function() { JobDetails.initJobDetails()  },
    onLeave:          function() { JobDetails.leaveJobDetails() },
    onLeaveCompleted: function() {}
  }).init()

  // Page devenir membre _______
  Barba.BaseView.extend({
    namespace: 'becomeMemberView',
    onEnter:          function() {},
    onEnterCompleted: function() { BecomeMember.initBecomeMember()  },
    onLeave:          function() { BecomeMember.leaveBecomeMember() },
    onLeaveCompleted: function() {}
  }).init()

  // Page répertoire __________
  Barba.BaseView.extend({
    namespace: 'repertoireView',
    onEnter:          function() {},
    onEnterCompleted: function() { Repertoire.initRepertoire()  },
    onLeave:          function() { Repertoire.leaveRepertoire() },
    onLeaveCompleted: function() {}
  }).init()

  // Page résultats de recherche __________
  Barba.BaseView.extend({
    namespace: 'rechercheView',
    onEnter:          function() {},
    onEnterCompleted: function() { Recherche.initRecherche()  },
    onLeave:          function() { Recherche.leaveRecherche() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Nous joindre _________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()

}
