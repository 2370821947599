//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Fonction qui gère le label des input
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input,textarea').focusin(function() {
    $(this).removeClass('notEmpty')
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input, textarea').focusout(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('.input-field select').change(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  $('body').on('change keydown keyup keydown paste cut', 'textarea', function() {
    $(this).height(0).height(this.scrollHeight)
  }).find('textarea').change()
}


// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
      autorisation: 'Vous devez cocher cette option.'
    }
  }
}



// LES RÈGLES __________
// Règle du emailB
export function validationEmail() {
  jQuery.validator.addMethod('courriel', function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value)
  })
}



// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {'translation': {
    A: {pattern: /[A-Za-z]/},
    B: {pattern: /[0-9]/}
  }})
}

// Format de la date
export function formatDate(element) {
  $(element).mask('00/00/00')
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') eval(handler + '()')
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
  })
}


//**********  Initialisation du select de changement de catégorie
export function initJobsCategFilter() {
  $('#jobsSelectFilter select').change(function() {
    var target = '.'+$(this).val();
    $('html, body').animate({ scrollTop: $(target).offset().top - 60 }, 300, 'easeInOutExpo' );
  });
}


//**********  Validation du formulaire emploi
export function emploiForm(form, submit) {
       
	var formEmploiValidator;

	$(form + ' input[type=file]').change(function() {

		var value = $(this).val().split(/(\\|\/)/g).pop();
		if ( value )
		{
			$(this).addClass('fileAdded');
			$(this).next('label').find('span.withFile').text(value);
		}
		else
		{
			$(this).removeClass('fileAdded');
			$(this).next('label').find('span.withFile').text('');
		}

		formEmploiValidator.element('#' + $(this).attr('id'));
	});
	
	formatPhone('#JobTelephone');
	$.validator.setDefaults({ignore: []});
	$.validator.addMethod('filesize', function (value, element, param) {
        return this.optional(element) || (element.files[0].size <= param)
    }, 'La taille maximale du fichier doit être 5MB');
	
	var m = getMessages();
	
    var rules = {
		nom: 			{ required: true },
		prenom: 		{ required: true },
		courriel: 		{ required: true, email: true },
		lettre: 	{ accept: "pdf", filesize: 5242880 },
		cv: 			{ required: true, accept: "pdf", filesize: 5242880 },
		telephone: 		{ required: true, minlength: 14 },
    };

    var messages = {
		nom: 			{ required: m.required },
		prenom: 		{ required: m.required },
		courriel: 		{ required: m.required, email: m.email },		
		cv:				{ required: m.file, accept: 'Format pdf seulement.' },
		lettre: 	{ accept: 'Format pdf seulement.' },		
		telephone: 		{ required: m.required, minlength: m.phone },
    };
	
  formEmploiValidator = $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement : 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error');
      if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element);
      }
    },
    submitHandler:  function(form) {

      $.oc.stripeLoadIndicator.show();
      $(submit).addClass('oc-loading');

      var formData = new FormData(form);
      $.ajax({
        url: '/la-chambre/travailler-a-la-chambre',
        headers: { 'X-OCTOBER-REQUEST-HANDLER':'onCarriereSubmit', },
        type: 'POST',
        data: formData,
        async: true,
        success: function (data) { UpdatePartial('#formContainer', data); $.oc.stripeLoadIndicator.hide(); },
        cache: false,
        contentType: false,
        processData: false,
        dataType: 'json'
      });
    }
  });

	$(submit).click( function() {
		$(form).submit();
		return false;
	});
}


//**********  Validation du formulaire contact
export function contactForm(form, submit) {
  var m = getMessages();
  formatPhone('#contactTelephone');
  var rules = 			{
  nom: 			{ required: true },
  prenom: 		{ required: true },
  courriel: 		{ required: true, email: true },
  telephone: 		{ required: true, minlength: 14 },
  message: 		{ required: true }
  };
  var messages = 			{
  nom: 			{ required: m.required },
  prenom: 		{ required: m.required },
  courriel: 		{ required: m.required, email: m.email },
  telephone: 		{ required: m.required, minlength: m.phone },
  message: 		{ required: m.required }
  };
  formValidation(form, submit, rules, messages);
}


//**********  Validation d'inscription au répertoire
export function inscriptionForm(form, submit) {
  var m = getMessages();
  formatPhone('#MembreTelephone,#MembreCellulaire');
  formatCodePostal('#MembreCodePostal');
  jQuery.datetimepicker.setLocale('fr');
  $('#MembreDateImmatriculation').datetimepicker({
    timepicker: false,
    format:'Y/m/d',
    maxDate: -0
  })

  $("#formMembre select").chosen()
  $("#formMembre select").change( function() {
    $(this).next().addClass("hasChanged");
  });

  //Champs cachés pour confirmation courriel
  $("#formMembre #MembreVille").change( function() {
    $("#formMembre #MembreVilleTexte").val($( "#formMembre #MembreVille option:selected" ).text());
  });
  $("#formMembre #MembreSecteurActivite").change( function() {
    $("#formMembre #MembreSecteurTexte").val($( "#formMembre #MembreSecteurActivite option:selected" ).text());
  });

  var rules = 			{
  nom: 			{ required: true },
  prenom: 		{ required: true },
  organisation: 		{ required: true },
  adresse: 		{ required: true },
  ville: 		{ required: true },
  codePostal: 		{ required: true },
  telephone: 		{ required: true, minlength: 14 },
  courriel: 		{ required: true, email: true },
  courrielFacturation: 		{ required: true, email: true },
  secteurActivite: 		{ required: true },
  autorisation: 		{ required: true },
  };
  var messages = 			{
  nom: 			{ required: m.required },
  prenom: 		{ required: m.required },
  organisation: 		{ required: m.required },
  adresse: 		{ required: m.required },
  ville: 		{ required: m.required },
  codePostal: 		{ required: m.required },
  telephone: 		{ required: m.required, minlength: m.phone },
  courriel: 		{ required: m.required, email: m.email },
  courrielFacturation: 		{ required: m.required, minlength: m.phone },
  secteurActivite: 		{ required: m.required, minlength: m.phone },
  autorisation: 	{ required: m.autorisation },
  };
  formValidation(form, submit, rules, messages);
}


function UpdatePartial(formid, data ) {
	for (var key in data) 
	{ 
		console.log('key: ' + key);
		console.log('data: ' + data[key]);
		$(key).html(data[key]); 
	}
	$('html, body').animate({ scrollTop: $(formid).offset().top - 180 }, 200, 'easeInOutExpo' );
}

// Afficher ou cacher les champs des délégués
export function gererDelegues() {
  if($("#adhesionDelegues").prop('checked')){
    $("#MembresDeleguesSection").show();
  }else{
    $("#MembresDeleguesSection").hide();
  }
}

export function initDelegues() {
  gererDelegues();
  $('#adhesionDelegues').change(function() {
    gererDelegues();
  });
}

//**********  Validation d'inscription au répertoire
export function newsletterForm(form, submit) {
  var m = getMessages();

  var rules = 			{
  courriel: 		{ required: true, email: true },
  };
  var messages = 			{
  courriel: 		{ required: m.required, email: m.email },
  };
  formValidation(form, submit, rules, messages);
}

//**********  Validation d'inscription aux Excelsiors
export function excelsiorsForm(form, submit) {
  var m = getMessages();
  var rules = 			{
  nom: 			{ required: true },
  prenom: 		{ required: true },
  courriel: 		{ required: true, email: true },
  organisation: 		{ required: true }
  };
  var messages = 			{
  nom: 			{ required: m.required },
  prenom: 		{ required: m.required },
  courriel: 		{ required: m.required, email: m.email },
  organisation: 		{ required: m.required }
  };
  formValidation(form, submit, rules, messages);
}