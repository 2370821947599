// Gestion des filtres des nouvelles
export function initEventsFilter() {
    $('#eventsSelectFilter select').chosen()
}

export function initPublicationsFilter() {
    $('#publicationsSelectFilter select').chosen()
}

export function initJobsFilter() {
    $('#jobsSelectFilter select').chosen()
}

export function initRechercheFilter() {
  $('#providerSelectFilter select').chosen()
}

export function initFilterOverlay() {
    $('.chosen-select.overlay').next().click(function() {
		$('html').toggleClass('show-filterModal');
    });
    $('.grayOverlay').click(function() {
		$('html').removeClass('show-filterModal');
    });
    
    //$('.chosen-select.overlay').next().mouseout(function() {
	//	$('html').removeClass('show-filterModal');
	//});
}