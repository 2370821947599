//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { slickHome, slickEvents, slickTestimonials, slickPublicites, destroySlick } from './functions/slicks.js'
import { noSamePageDestination, removeHoverOnMobile, tiroirs, backToTopBtn, magicSticky, filterClick } from './functions/functions.js'
import { initEventsFilter, initPublicationsFilter, initJobsFilter, initRechercheFilter, initFilterOverlay } from './functions/chosen.js'
import { showSticky, hideSticky } from './functions/sticky.js'
import { closeAllOnResize, overlayMenuMobile, overlayDropDown, overlayDropDownSearch } from './functions/overlay.js'
import { initShareModal } from './functions/shareModal.js'
import { fixInput, emploiForm, initJobsCategFilter, inscriptionForm, contactForm, initDelegues, newsletterForm, excelsiorsForm } from './functions/forms.js'
//-----------------------------------------------------------------------------------------------------------------


// Initialisation sur toutes les pages
export class View {
  static init() {
    noSamePageDestination()
    removeHoverOnMobile()
    closeAllOnResize()
    overlayMenuMobile()
    overlayDropDown()
    overlayDropDownSearch()
    backToTopBtn()
    initShareModal()
    magicSticky()
    slickPublicites()
    newsletterForm("#newsletterFooterForm","#newsletterFooterFormSubmit")
    $.fn.BLK_scrollfire()
  }
  static leave() {
    destroySlick(".bigBoxPublicites");
    destroySlick(".smallBoxPublicites");
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    slickHome()
    slickEvents()
    slickTestimonials()
    showSticky(4000)
    hideSticky()
  }
  static leaveHome() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans les pages génériques
export class Generic extends View {
  static initGeneric() {
    this.init()
    tiroirs()
    excelsiorsForm('#formInscriptionExcelsiors', '#submitInscription')
  }
  static leaveGeneric() {
    this.leave()
  }
}


// Classe initialisant les scripts présents la liste des événements
export class Events extends View {
  static initEvents() {
    this.init()
    initEventsFilter()
    initFilterOverlay()
  }
  static leaveEvents() {
    this.leave()
  }
}


// Classe initialisant les scripts présents la liste des événements
export class Publications extends View {
  static initPublications() {
    this.init()
    initPublicationsFilter()
    initFilterOverlay()
  }
  static leavePublications() {
    this.leave()
  }
}


// Classe initialisant les scripts les détails d'un poste
export class Jobs extends View {
  static initJobs() {
    this.init()
    initJobsFilter()
    initJobsCategFilter()
    initFilterOverlay()
    fixInput()
    emploiForm('#applicationForm', '#submitJob')
  }
  static leaveJobs() {
    this.leave()
  }
}


// Classe initialisant les scripts les détails d'un poste
export class JobDetails extends View {
  static initJobDetails() {
    this.init()
    fixInput()
    emploiForm('#applicationForm', '#submitJob')
  }
  static leaveJobDetails() {
    this.leave()
  }
}


// Classe initialisant les scripts les détails d'un poste
export class BecomeMember extends View {
  static initBecomeMember() {
    this.init()
    fixInput()
    inscriptionForm('#formMembre','#submitMembre')
    initDelegues()
  }
  static leaveBecomeMember() {
    this.leave()
  }
}


// Classe initialisant les scripts les détails d'un poste
export class Repertoire extends View {
  static initRepertoire() {
    this.init()
    tiroirs()
    filterClick()
  }
  static leaveRepertoire() {
    this.leave()
  }
}


// Classe initialisant les scripts présents sur la page de résultats de recherche
export class Recherche extends View {
  static initRecherche() {
    this.init()
    initRechercheFilter()
    initFilterOverlay()
  }
  static leaveRecherche() {
    this.leave()
  }
}


// Classe initialisant les scripts présents sur la page contact
export class Contact extends View {
  static initContact() {
    this.init()
    fixInput()
    contactForm("#formContact","#submitContact")
  }
  static leaveContact() {
    this.leave()
  }
}