//-------------------------------\\
//--- Importation des scripts ---\\
//-------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation des nodes modules __________
import $ from 'jquery'
window.jQuery = $
import 'jquery.easing'
import 'jquery-validation'
import 'jquery-validation/dist/additional-methods'
import 'slick-carousel'
import 'jquery-mask-plugin'
import 'jquery-datetimepicker/build/jquery.datetimepicker.full.js'
import '../styles/style.scss'


// Importation des scripts __________
import './plugins/smoothScroll.js'
import './plugins/scrollfire.js'
import './plugins/lazyload.js'
import './plugins/chosen.js'
import './transition.js'
import './global.js'


// Importation pour les scripts un peu plus bas __________
import { Loader } from './classes/loader.js'
import { initPageTransition } from './transition.js'

import { eventsLoaded, newsLoaded, membersLoaded, tiroirs } from './functions/functions.js'
window.eventsLoaded = eventsLoaded;
window.newsLoaded = newsLoaded;
window.membersLoaded = membersLoaded;
window.tiroirs = tiroirs;

// Démarrage des scripts __________
$(document).ready(function() {
  initPageTransition()
  //var firstloader = new Loader()
  //firstloader.reset()
  //firstloader.setCompleteCallback([function() {
    setTimeout(function() {
      $('html').removeClass('chargement')
      $.fn.BLK_scrollfire.init()
    }, 1500) //600
  //}])
  //firstloader.start()
  $(document).scrollTop(0)
  $.fn.BLK_smoothScroll()

  $('body').keydown( function(e) {
    var keycode = (e.keyCode ? e.keyCode : e.which)
    if ((e.ctrlKey  && keycode == 13) || (e.metaKey  && keycode == 13)) {
      location = '/administration'
      return false
    }
  })

})
