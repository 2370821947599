//-----------------------------\\
//--- Transition avec barba ---\\
//-----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'
import { setView } from './views.js'
import { Loader } from './classes/loader.js'
//-----------------------------------------------------------------------------------------------------------------

// Cette fonction Gère la transition entre les page
export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'

  //Barba.Pjax.preventCheck = function(evt, element) {
  //    /* VIRER LES PDFs */
  //
  //    var linkHref = $(element).attr('href');
  //    var regex = /.*\.pdf$/gm;
  //    var regexTest = regex.test(linkHref);
  //    if ( regexTest ) return false;
  //
  //    if ( typeof linkHref != 'undefined') {
  //        /* VIRER LES SITES EXTERNES */
  //        var localhost = window.location.hostname
  //        var newUrl = extractHostname( linkHref )
  //
  //        if ( newUrl != '' ) {
  //            if ( localhost != newUrl ) {
  //                return false;
  //            }
  //        }
  //    }
  //    return true;
  //}

  Barba.Dispatcher.on('initStateChange', function(currentStatus) {
    if( typeof fbq === 'function'  ) fbq('track', 'PageView') // Facebook pixel
  })
  setTransition()
  setView()
  Barba.Pjax.start()
}

function extractHostname(url) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
  }
  else {
      hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}


// Cette fonctions exécute la transition entre les pages
function setTransition() {
  //var preloader = new Loader()
  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },
    fadeOut: function() {
      //preloader.reset()
      $('.hamburger').removeClass('is-active')
      return $('html').addClass('transition').delay(1800).promise()
    },
    fadeIn: function() {
      //preloader.start()
      if (!window.location.hash) window.scrollTo(0, 0)
      //preloader.setCompleteCallback([ () => {
        $('html').removeClass('transition')
        $.fn.BLK_scrollfire.init()
        //$('#slickSlides').slick('slickSetOption', 'autoplay', true, true)
        this.done()
      //}])
    }
  })

  Barba.Pjax.getTransition = function() {
    return FadeTransition
  }

}
